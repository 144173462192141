.footerContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: stretch;
  align-content: stretch;
  margin-top: 50px;
}

.footerContainer p {
  margin-right: 2%;
  width: 100%;
}

.footerContainer hr {
  width: 100%;
}

.footerContainer .iconContainer {
  display: flex;
  flex-wrap: nowrap;
  margin-left: 89%;
}

.footerContainer .iconContainer .icon {
  flex: 1 1 auto;
  padding: 2%;
}

.footerContainer .iconContainer .icon .img {
  height: 60px;
  width: 60px;
}

@media (max-width: 576px) {
  .footerContainer .iconContainer {
    margin-left: 75%;
  }

  .footerContainer .iconContainer .icon .img {
    height: 40px;
    width: 40px;
  }
}

@media (max-width: 768px) and (min-width: 576px) {
  .footerContainer .iconContainer .icon .img {
    height: 28px;
    width: 28px;
  }
}

@media (max-width: 992px) and (min-width: 768px) {
  .footerContainer .iconContainer .icon .img {
    height: 39px;
    width: 39px;
  }
}

@media (max-width: 1200px) and (min-width: 992px) {
  .footerContainer .iconContainer .icon .img {
    height: 50px;
    width: 50px;
  }
}
