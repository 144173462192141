.navContainer{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: stretch;
  align-content: stretch;
  margin-top: 0px;
}

.navContainer .navButton{
  background-color: black;
  color: white;
  height: 40px;
  width: 33.33%;
  margin-top: -10px;
  cursor: pointer;
  font-size: 100%;
  border-color: black;
  outline: none;
}

.navContainer .navButton:hover{
  background-color: grey;
  border-color: black;
}

@media (max-width: 576px) {
  .navContainer .navButton{
    font-size: 100%;
  }
}

@media (max-width: 768px) and (min-width: 576px) {
  .navContainer .navButton{
    font-size: 70%;
  }
}

@media (max-width: 992px) and (min-width: 768px) {
  .navContainer .navButton{
    font-size: 80%;
  }
}

@media (max-width: 1200px) and (min-width: 992px) {
  .navContainer .navButton{
    font-size: 90%;
  }
}
