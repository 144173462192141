#contactContainer{
  background-color: grey;
  font-family: "Times New Roman", Times, serif;
}

#contactContainer #sendMessage {
  float: left;
  width: 94%;
  margin-left: 1%;
  margin-top: 35px;
  font-size: 130%;
  width:94%;
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-color: black;
  border-radius: 0;
  padding: 2%;
}

#contactContainer .text{
  float: left;
  margin-left: 5%;
  margin-top: 35px;
  padding: 1%;
  font-size: 110%;
  border-style: solid;
  border-width: 1px;
  border-color: black;
  border-radius: 0;
  background-color: white;
  width:42%;
  box-shadow: 0px 0px 5px black;
}

#contactContainer .textField{
  width: 100%;
  height: 30px;
  padding: 1%;
  border-style: solid;
  border-width: 1px;
  border-color: black;
  border-radius: 0;
  font-size: 130%;
  width: 98%;
}

#contactContainer .button{
  margin-top: 10px;
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-color: black;
  width: 100%;
  height: 70px;
  font-size: 120%;
  text-decoration: none;
  box-shadow: 0px 0px 5px black;
}

#contactContainer .button:hover {
  background-color: black;
  color: white;
  cursor: pointer;
  box-shadow: 0px 0px 5px black;
}

#contactContainer #contactTitle{
  text-align: center;
  font-size: 150%;
}

#contactContainer #message{
  font-family: "Times New Roman", Times, serif;
  height: 100px;
}

#contactContainer #messageSent{
  position: absolute;
  font-family: "Times New Roman", Times, serif;
  height: 100px;
  margin-left: 30%;
  margin-top: 35px;
}