@import url("About.css");
@import url("Contact.css");
@import url("Navigation.css");
@import url("Portfolio.css");
@import url("Resume.css");
@import url("Footer.css");

body {
  background-color: grey;
}
  