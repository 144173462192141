.aboutContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: stretch;
  align-content: stretch;
  margin-top: 35px;
}

.aboutContainer .me {
  flex: 1 1 auto;
  height: 600px;
  width: 40%;
  margin-left: .5%;
  border-style: solid;
  border-width: 1px;
  border-color: black;
  box-shadow: 0px 0px 1px black;
}

.aboutContainer .about {
  flex: 1 1 auto;
  height: 600px;
  width: 40%;
  background-color: white;
  padding-left: 1%;
  padding-right: 1%;
  font-size: 110%;
  margin-right: .5%;
  border-style: solid;
  border-width: 1px;
  border-color: black;
  box-shadow: 0px 0px 1px black;
}

@media (max-width: 575px) {
  .aboutContainer {
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-top: 5px;
  }

  .aboutContainer .me {
    margin-left: 0;
    height: 400px;
    width: 100%;
  }

  .aboutContainer .about {
    margin-right: 0;
    margin-left: 0;
    height: auto;
    margin-top: 5px;
    font-size: 100%;
    width: 98%;
  }
}

@media (max-width: 768px) and (min-width: 576px) {
  .aboutContainer .me {
    height: 300px;
  }

  .aboutContainer .about {
    font-size: 55%;
    height: 300px;
  }
}

@media (max-width: 992px) and (min-width: 768px) {
  .aboutContainer .me {
    height: 405px;
  }

  .aboutContainer .about {
    font-size: 75%;
    height: 405px;
  }
}

@media (max-width: 1200px) and (min-width: 992px) {
  .aboutContainer .me {
    height: 450px;
  }

  .aboutContainer .about { 
    font-size: 85%;
    height: 450px;
  }
}
