.portfolioContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  margin-top: 35px;
}

.portfolioContainer .project {
  flex: 1 1 auto;
  height: 325px;
  max-width: 30%;
  background-color: #595959;
  padding-left: 1%;
  padding-right: 1%;
  font-size: 110%;
  margin-right: .5%;
  border-style: solid;
  border-width: 1px;
  border-color: black;
  box-shadow: 0px 0px 1px black;
}

.portfolioContainer .title {
  width: 100%;
  font-size: 300%;
  text-shadow: 1px 1px 1px black;
}

.portfolioContainer .project .image {
  margin-top: 10px;
}

.portfolioContainer .project .button {
  margin-top: 5px;
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-color: black;
  color: black;
  width: 100%;
  height: 70px;
  font-size: 120%;
  text-decoration: none;
  box-shadow: 0px 0px 5px black;
}

.portfolioContainer .project .button:hover {
  background-color: black;
  border-style: solid;
  border-width: 1px;
  border-color: white;
  color: white;
  cursor: pointer;
  box-shadow: 0px 0px 5px black;
}

@media (max-width: 576px) {
  .portfolioContainer .project .button {
    height: 40px;
    font-size: 125%;
  }
  
  .portfolioContainer .project {
    height: 275px;
    font-size: 80%;
    min-width: 98%;
    margin-left: 0%;
    margin-bottom: 10px;
  }
  
  .portfolioContainer .title{
    font-size: 300%;
    text-align: center;
  }
  
  .portfolioContainer .project .image{
    height: 200px;
  }
}

@media (max-width: 768px) and (min-width: 576px) {
  .portfolioContainer .project .button{
    height: 20px;
    font-size: 170%;
  }

  .portfolioContainer .project{
    height: 250px;
    font-size: 48%;
    width: 44.5%;
  }

  .portfolioContainer .title{
    font-size: 270%;
  }

  .portfolioContainer .project .image{
    width: 100%;
    height: 225px;
  }
}

@media (max-width: 992px) and (min-width: 768px) {
  .portfolioContainer .project .button{
    height: 30px;
    font-size: 180%;
  }

  .portfolioContainer .project{
    height: 280px;
    font-size: 60%;
    width: 44.5%;
  }

  .portfolioContainer .title{
    font-size: 280%;
  }

  .portfolioContainer .project .image{
    width: 100%;
    height: 225px;
  }
}

@media (max-width: 1200px) and (min-width: 992px) {
  .portfolioContainer .project .button{
    height: 40px;
    font-size: 110%;
  }

  .portfolioContainer .project{
    height: 270px;
    font-size: 80%;
  }

  .portfolioContainer .title{
    font-size: 290%;
  }

  .portfolioContainer .project .image{
    height: 200px;
  }
}
