.resumeContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  align-content: stretch;
  margin-top: 35px;
}

.resumeContainer .resume {
  border-style: solid;
  border-width: 1px;
  border-color: black;
  box-shadow: 0px 0px 1px black;
  padding-left: 1%;
  padding-right: 1%;
  font-size: 110%;
  width:80%;
  background-color: white;
}

.resumeContainer .resume .button {
  float: right;
  margin-top: 5px;
  width: 2.5%;
  height: 25px;
}

@media (max-width: 575px) {
  .resumeContainer .resume {
    font-size: 90%;
    margin-left: 0%;
    width: 98%;
  }

  .resumeContainer .resume .button {
    width: 5%;
    height: 15px;
  }
}

@media (max-width: 768px) and (min-width: 576px) {
  .resumeContainer .resume {
    font-size: 55%;
  }

  .resumeContainer .resume .button {
    width: 3.5%;
    height: 18px;
  }
}

@media (max-width: 992px) and (min-width: 768px) {
  .resumeContainer .resume {
    font-size: 75%;
  }

  .resumeContainer .resume .button {
    height: 20px;
  }
}

@media (max-width: 1200px) and (min-width: 992px) {
  .resumeContainer .resume {
    font-size: 85%;
  }

  .resumeContainer .resume .button {
    height: 20px;
  }
}
